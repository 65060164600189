import React from "react";
import { Link } from 'react-router-dom'
import './component.scss'

export default function tncMenu(link) {
    return (
        <div className="w-[292px] border-[1px] border-[#A4A6AD] tncMenu rounded-[8px]">
            <ul>
                <li className={link.url === '/syarat-dan-ketentuan' ? 'font-bold text-[#222358]' : 'font-medium text-[#6A6F7A]'}>
                    <Link to="/syarat-dan-ketentuan">
                        Syarat dan Ketentuan
                    </Link>
                </li>
                <li className={link.url === '/kebijakan-privasi' ? 'font-bold text-[#222358]' : 'font-medium text-[#6A6F7A]'}>
                    <Link to="/kebijakan-privasi">
                        Kebijakan Privasi
                    </Link>
                </li>
            </ul>
        </div >
    );
}