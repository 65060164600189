import React from "react";
import '../component.scss'
import Whatsapp from "../../assets/images/section5/whatsapp.svg"

export default function Section5() {
    return (
        <div className='relative section5 h-[383px] md:h-[610px] w-full'>
            <div className="content w-full max-w-[1280px] px-[2rem]">
                <div data-aos="zoom-out">
                    <h2 className="mb-[30px]">Unduh Gratis Sekarang Juga</h2>
                </div>
                <div data-aos="zoom-out">
                    <p className="mb-[41px]">Cobalah beragam aplikasi yang dimiliki awan digital atau WhatsApp jika perlu info lanjutan
                    </p>
                </div>
                <button className="btn" data-aos="zoom-out">
                    <img src={Whatsapp} alt="" /> WhatsApp Kami
                </button>
            </div>
        </div>
    );
}