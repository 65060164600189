import React, { useState } from "react";
import '../component.scss'
import Flash from '../../assets/images/section2/flash.png'
import Kyoo from '../../assets/images/logo/kyoo.png'
import Sikas from '../../assets/images/logo/sikas.png'
import SiQasir from '../../assets/images/logo/siqasir.png'
import Skrin from '../../assets/images/logo/skrin.png'
import SikasPhone from '../../assets/images/section2/sikas_phone.png'
import KyooContent from '../../assets/images/section2/kyoo-image-content.png'
import SiQasirContent from '../../assets/images/section2/siqasir-content.png'
import KyooSection from '../../assets/images/section2/kyoo-section.png'
import SkrinSection from '../../assets/images/section2/skrin-section.png'
import Check from '../../assets/images/section2/check.png'

export default function Section2() {
    const [activeButton, setActiveButton] = useState(1);
    const [product, setProduct] = useState('Semua Produk')

    function updateSelectedProgram(index) {
        setActiveButton(index)
    }

    function changeProduct(e) {
        setProduct(e)
    }

    return (
        <div className='mt-[76px] w-full section2 h-[1282px]'>
            <div className="hidden md:block max-w-[1280px] mx-auto px-[2rem]">
                <div className="top-section">
                    <svg className="mx-auto -mt-[50px]" width="137" height="137" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M68.5 -6.10352e-05L72.3807 23.5601C75.8517 44.6324 92.3675 61.1483 113.44 64.6192L137 68.4999L113.44 72.3807C92.3675 75.8516 75.8517 92.3675 72.3807 113.44L68.5 137L64.6193 113.44C61.1483 92.3675 44.6325 75.8516 23.5602 72.3807L0 68.4999L23.5602 64.6192C44.6325 61.1483 61.1483 44.6324 64.6193 23.5601L68.5 -6.10352e-05Z" fill="#FAFAFA" />
                    </svg>
                    <img data-aos="zoom-in" src={Flash} alt="" />
                </div>
                <div className="content container max-w-container title mx-auto pt-[104px] grid grid-cols-2 gap-x-[50px]">
                    <div className="col-span-1 title left" data-aos="zoom-out">
                        <h2>Aplikasi untuk segala kebutuhan bisnis anda</h2>
                    </div>
                    <div className="col-span-1 title right" data-aos="zoom-out">
                        <p>
                            Produk yang dapat diterima oleh kalangan manapun membantu trasformasi pekerjaan manual menjadi digital
                        </p>
                    </div>
                </div>
                <div className="content container max-w-container mx-auto pt-[57px] grid grid-col-2 gap-x-[62px] gap-y-[60px]">
                    <div className="btn-group col-span-2 justify-center">
                        <input type="radio" name="options" id="all" data-title="Semua Produk" className="btn" checked={activeButton === 1} onChange={updateSelectedProgram.bind(this, 1)} />
                        <input type="radio" name="options" data-title="Kyoo" className="btn" checked={activeButton === 2} onChange={updateSelectedProgram.bind(this, 2)} />
                        <input type="radio" name="options" data-title="Skrin" className="btn" checked={activeButton === 3} onChange={updateSelectedProgram.bind(this, 3)} />
                        <input type="radio" name="options" data-title="SiQasir" className="btn" checked={activeButton === 4} onChange={updateSelectedProgram.bind(this, 4)} />
                        <input type="radio" name="options" data-title="SiKAS" className="btn" checked={activeButton === 5} onChange={updateSelectedProgram.bind(this, 5)} />
                    </div>
                    <div className={activeButton === 1 ? `col-span-1 medium sikas card` : `hidden`}>
                        <img src={Sikas} className="sikas mx-auto" data-aos="zoom-out" alt="" />
                        <img src={SikasPhone} className="sikas" data-aos="zoom-out" alt="" />
                    </div>
                    <div className={activeButton === 1 ? `col-span-1 medium kyoo card` : `hidden`}>
                        <img src={Kyoo} className="kyoo mx-auto" data-aos="zoom-out" alt="kyoo" />
                        <img src={KyooContent} className="kyoo" data-aos="zoom-out" alt="kyoo" />
                    </div>
                    <div className={activeButton === 1 ? `col-span-1 medium kyoo card` : `hidden`}>
                        <img src={SiQasir} className="sikas mx-auto" data-aos="zoom-out" alt="" />
                        <img src={SiQasirContent} className="sikas" data-aos="zoom-out" alt="" />
                    </div>
                    <div className={activeButton === 1 ? `col-span-1 medium kyoo card` : `hidden`}>
                        <img src={Skrin} className="kyoo mx-auto" data-aos="zoom-out" alt="kyoo" />
                        <img src={SkrinSection} className="kyoo" data-aos="zoom-out" alt="kyoo" />
                    </div>
                    <div className={activeButton === 2 ? `col-span-2 card big kyoo grid grid-cols-5` : `hidden`}>
                        <div className="col-span-2">
                            <img src={Kyoo} className="kyoo" alt="kyoo" />
                            <p>
                                Solusi Antri dan Booking Online membantu umkm dan institusi kecil untuk memberikan pelayanan dan experience terbaik kepada pelanggan.
                            </p>

                            <ul className="mt-[35px]">
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                            </ul>

                            <button className="btn"> Pelajari lebih lanjut </button>
                        </div>
                        <div className="col-span-3">
                            <img src={KyooSection} className="kyoo" alt="kyoo" />
                        </div>
                    </div>
                    <div className={activeButton === 3 ? `col-span-2 card big kyoo grid grid-cols-5` : `hidden`}>
                        <div className="col-span-2">
                            <img src={Skrin} className="kyoo" alt="skrin" />
                            <p>
                                Solusi Antri dan Booking Online membantu umkm dan institusi kecil untuk memberikan pelayanan dan experience terbaik kepada pelanggan.
                            </p>

                            <ul className="mt-[35px]">
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                            </ul>

                            <button className="btn"> Pelajari lebih lanjut </button>
                        </div>
                        <div className="col-span-3">
                            <img src={SkrinSection} className="kyoo" alt="kyoo" />
                        </div>
                    </div>
                    <div className={activeButton === 4 ? `col-span-2 card big kyoo grid grid-cols-5` : `hidden`}>
                        <div className="col-span-2">
                            <img src={SiQasir} className="kyoo" alt="kyoo" />
                            <p>
                                Solusi Antri dan Booking Online membantu umkm dan institusi kecil untuk memberikan pelayanan dan experience terbaik kepada pelanggan.
                            </p>

                            <ul className="mt-[35px]">
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                            </ul>

                            <button className="btn"> Pelajari lebih lanjut </button>
                        </div>
                        <div className="col-span-3">
                            <img src={KyooSection} className="kyoo" alt="kyoo" />
                        </div>
                    </div>
                    <div className={activeButton === 5 ? `col-span-2 card big kyoo grid grid-cols-5` : `hidden`}>
                        <div className="col-span-2">
                            <img src={Sikas} className="kyoo" alt="kyoo" />
                            <p>
                                Solusi Antri dan Booking Online membantu umkm dan institusi kecil untuk memberikan pelayanan dan experience terbaik kepada pelanggan.
                            </p>

                            <ul className="mt-[35px]">
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                            </ul>

                            <button className="btn"> Pelajari lebih lanjut </button>
                        </div>
                        <div className="col-span-3">
                            <img src={KyooSection} className="kyoo" alt="kyoo" />
                        </div>
                    </div>
                   
                </div>
            </div>
            <div className="block md:hidden">
                <div>
                    <h3>
                        Solusi terbaik untuk perubahan yang lebih baik
                    </h3>
                    <p>
                        Produk yang dapat diterima oleh kalangan manapun membantu trasformasi pekerjaan manual menjadi digital
                    </p>

                    <select className="select w-full mb-[35px]" value={product} onChange={(e) => changeProduct(e.target.value)}>
                        <option>Semua Produk</option>
                        <option>SiKAS</option>
                        <option>Kyoo</option>
                        <option>SiQASIR</option>
                    </select>

                    <div className="content">
                        <div className={product === 'Semua Produk' ? 'all medium sikas card' : 'hidden'}>
                            <img src={Sikas} className="mx-auto " alt="" />
                            <img src={SikasPhone} className="sikas" alt="" />
                            <p>Aplikasi yang membantu bisnis Anda untuk mencatat transaksi dan utang piutang</p>
                            <button className="btn"> Pelajari lebih lanjut </button>
                        </div>

                        <div className={product === 'Semua Produk' ? 'all medium kyoo card' : 'hidden'}>
                            <img src={Kyoo} className="mx-auto " alt="" />
                            <img src={KyooContent} className="sikas" alt="" />
                            <p>Aplikasi yang membantu bisnis Anda untuk mencatat transaksi dan utang piutang</p>
                            <button className="btn"> Pelajari lebih lanjut </button>
                        </div>

                        <div className={product === 'Semua Produk' ? 'all medium kyoo card' : 'hidden'}>
                            <img src={SiQasir} className="mx-auto " alt="" />
                            <img src={SiQasirContent} className="sikas" alt="" />
                            <p>Aplikasi yang membantu bisnis Anda untuk mencatat transaksi dan utang piutang</p>
                            <button className="btn"> Pelajari lebih lanjut </button>
                        </div>

                        <div className={product === 'SiKAS' ? 'all long sikas card' : 'hidden'}>
                            <img src={Sikas} className="mx-auto " alt="" />
                            <img src={SikasPhone} className="sikas" alt="" />
                            <p>Aplikasi yang membantu bisnis Anda untuk mencatat transaksi dan utang piutang</p>
                            <ul className="mt-[35px]">
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                            </ul>
                            <button className="btn"> Pelajari lebih lanjut </button>

                        </div>

                        <div className={product === 'SiQASIR' ? 'all long sikas card' : 'hidden'}>
                            <img src={SiQasir} className="mx-auto " alt="" />
                            <img src={SiQasirContent} className="sikas" alt="" />
                            <p>Aplikasi yang membantu bisnis Anda untuk mencatat transaksi dan utang piutang</p>
                            <ul className="mt-[35px]">
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                            </ul>
                            <button className="btn"> Pelajari lebih lanjut </button>

                        </div>

                        <div className={product === 'Kyoo' ? 'all long kyoo card' : 'hidden'}>
                            <img src={Kyoo} className="mx-auto " alt="" />
                            <img src={KyooContent} className="sikas" alt="" />
                            <p>Aplikasi yang membantu bisnis Anda untuk mencatat transaksi dan utang piutang</p>
                            <ul className="mt-[35px]">
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                                <li>
                                    <img src={Check} className="check" alt="" />
                                    Lorem ipsum dolor sit amet.
                                </li>
                            </ul>
                            <button className="btn"> Pelajari lebih lanjut </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}