import React, { useState, useEffect } from 'react'
import Logo from '../assets/images/logo/awandigital.png';
import Kyoo from '../assets/images/logo/kyoo.png';
import Sikas from '../assets/images/logo/sikas.png';
import Siqasir from '../assets/images/logo/siqasir.png';
import Skrin from '../assets/images/logo/skrin.png';
import { useParams } from "react-router-dom";
import ApiService from '../../src/services/api.service';
import { Link, useNavigate } from 'react-router-dom'

export default function Usecase() {
    const { id } = useParams();
    const navigate = useNavigate()
    const [packages, setPackages] = useState([])
    const [product, setProduct] = useState(null)
    var packageList = [];

    useEffect(() => {
        ApiService
            .get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/product/' + id)
            .then((response) => {
                response = response.data.data
                setProduct(response.products)
                setPackages(response.packages)

            })
            .catch((error) => {
                navigate('/login')
            })
    }, []);

    function loopData() {
        if (packages.length !== 0) {
            for (const [index, value] of packages.entries()) {
                packageList.push(
                    <li key={index}>
                        <div className='flex justify-between'>
                            <div className='flex'>
                                <input type="radio" name="radio-3" className="radio radio-primary" />
                                <p className='type'>{value.name}</p>
                            </div>
                            <div className=''>
                            {value.price == "Hubungi Kami" ? 
                                    (<p className='price'>
                                        Hubungi Kami
                                    </p>)
                                    : (<p className='price'>
                                        Rp {value.price}/mo
                                    </p>)}
                            </div>
                        </div>
                        <div>
                            <p className='license-description'>
                                {value.description}
                            </p>
                        </div>
                    </li>
                )
            }
        }
    }

    function generateProductLogo(value) {
        if (product.name === 'Kyoo') {
            return (
                <img src={Kyoo} alt="" />
            )
        } else if (product.name === 'Sikas') {
            return (
                <img src={Sikas} alt="" />
            )
        } else if (product.name === 'Sikasir') {
            return (
                <img src={Siqasir} alt="" />
            )
        } else if (product.name === 'Skrin') {
            return (
                <img src={Skrin} alt="" />
            )
        }
    }

    function logout() {
        ApiService
            .get('/api/logout')
            .then(() => {
                window.location = '/login'
            })
    }

    loopData()

    function sendEmail() {
        window.location.assign("mailto:support@awandigital.id?Subject=" + encodeURIComponent('Tanya Informasi License Premium Skrin') + "&body=" + encodeURIComponent("pesan"));
    }

    if (product !== null) {
        return (
            <div className="w-full manage-subscription">
                <div className='header flex justify-between pl-[20px] md:pl-[40px] pr-[20px] md:pr-[61px] pt-[28px]'>
                    <div>
                        <img src={Logo} alt="" />
                    </div>
                    <div>
                        <button onClick={logout.bind(this)} href="./" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26.0112 22.4208L28.5712 19.8608L26.0112 17.3008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18.3311 19.8608H28.5011" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M20.3311 27.8008C15.9111 27.8008 12.3311 24.8008 12.3311 19.8008C12.3311 14.8008 15.9111 11.8008 20.3311 11.8008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className="flex-1 whitespace-nowrap text-[#ADADAD]">Keluar</span>
                        </button>
                    </div>
                </div>
                <div className='main'>
                    <div className='title'>
                        <div className='max-w-full px-[20px] md:px-0 md:max-w-[80vw] mx-auto'>
                            <button className='btn flex justify-between'>
                                <Link to="/dashboard" className='flex items-center'>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.4375 11.6094H6.72423L14.932 4.48438C15.0633 4.36953 14.9836 4.15625 14.8102 4.15625H12.7359C12.6445 4.15625 12.5578 4.18906 12.4899 4.24766L3.63282 11.9328C3.5517 12.0031 3.48664 12.0901 3.44205 12.1877C3.39747 12.2854 3.37439 12.3915 3.37439 12.4988C3.37439 12.6062 3.39747 12.7123 3.44205 12.8099C3.48664 12.9076 3.5517 12.9945 3.63282 13.0648L12.5414 20.7969C12.5766 20.8273 12.6188 20.8438 12.6633 20.8438H14.8078C14.9813 20.8438 15.0609 20.6281 14.9297 20.5156L6.72423 13.3906H20.4375C20.5406 13.3906 20.625 13.3062 20.625 13.2031V11.7969C20.625 11.6937 20.5406 11.6094 20.4375 11.6094Z" fill="black" />
                                    </svg>
                                    Manage Subscription
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className='content max-w-[80vw] mx-auto flex flex-wrap justify-between'>
                        <div className='basis-full md:basis-6/12 mb-[20px] md:mb-0'>
                            {generateProductLogo()}
                            <h3>Pilih plan Terbaik mu</h3>
                            <div className="collapse collapse-plus">
                                <input type="checkbox" className="peer" />
                                <div className="collapse-title text-primary-content px-0 flex">
                                    <div>
                                        Apa itu {product.name}
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="collapse-content text-primary-content px-0">
                                    <p>
                                        Skrin adalah aplikasi cloud Digital Signage yang digunakan untuk menyampaikan informasi melalui media display elektronik (LED, Web, TV, PC dll) yang dilakukan secara dinamis dan menarik. Digital Signage Display dapat menyampaikan informasi secara satu maupun dua arah menggunakan teknologi interaktif
                                    </p>
                                </div>
                            </div>

                            <div className="collapse collapse-plus">
                                <input type="checkbox" className="peer" />
                                <div className="collapse-title text-primary-content px-0 flex">
                                    <div>
                                        Apakah Keuntungan Menggunakan Premium Plan
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="collapse-content text-primary-content px-0">
                                    <p>
                                        Dengan menggunakan premium plan anda akan mendapatkan fitur yang lebih complete dan support layanan melalui WA, Email dan Phone.
                                    </p>
                                </div>
                            </div>

                            <div className="collapse collapse-plus">
                                <input type="checkbox" className="peer" />
                                <div className="collapse-title text-primary-content px-0 flex">
                                    <div>
                                        Bagaimana cara {product.name} Bekerja
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="collapse-content text-primary-content px-0">
                                    <p>
                                        Skrin menggunakan beberapa metode untuk menampilkan informasi Signage. Pertama menggunakan platform web digital dan kedua menggunakan integrasi API. Anda memiliki kebebasan untuk menggunakan metode yang sesuai dengan kebutuhan Anda.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='basis-full md:basis-5/12 license-type'>
                            <ul>
                                {packageList}
                            </ul>
                            <button className='btn' onClick={sendEmail.bind(this)}>Lanjutkan</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
