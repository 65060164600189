import React, { useState, useEffect } from 'react'
import Logo from '../assets/images/logo/awandigital.png';
import Kyoo from '../assets/images/logo/kyoo.png';
import Sikas from '../assets/images/logo/sikas.png';
import Siqasir from '../assets/images/logo/siqasir.png';
import Skrin from '../assets/images/logo/skrin.png';
import ApiService from '../services/api.service';
import { Link, useNavigate } from 'react-router-dom'

export default function ManageProduct() {
    const navigate = useNavigate()
    const [products, setProducts] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [user, setUser] = useState({})
    useEffect(() => {
        if (isLoaded === false) {
            setIsLoaded(true)
            ApiService.init()
            ApiService
                .get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/get-my-data')
                .then((response) => {
                    setUser({...response.data.data})
                    ApiService
                        .get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/company/' + response.data.data.companies[0].id)
                        .then((response) => {
                            setProducts(response.data.products);
                        })
                })
                .catch((error) => {
                    // navigate('/login')
                })
        }
    }, [isLoaded])

    function productLogo(value) {
        if (value === 'Kyoo') {
            return (
                <img src={Kyoo} className="w-[68px]" alt="" />
            )
        } else if (value === 'Sikas') {
            return (
                <img src={Sikas} className="w-[74.83px]" alt="" />
            )
        } else if (value === 'Sikasir') {
            return (
                <img src={Siqasir} className="w-[74.83px]" alt="" />
            )
        } else if (value === 'Skrin') {
            return (
                <img src={Skrin} className="w-[74.83px]" alt="" />
            )
        }
    }

    const [activeProduct, setActiveProduct] = useState(-1)

    function setSelectedProduct(index) {
        if (activeProduct === index) {
            setActiveProduct(-1)
        } else {
            setActiveProduct(index)
        }
    }

    var productList = [];
    function loopData() {
        if (products.length !== 0) {
            for (const [index, value] of products.entries()) {
                productList.push(
                    
                        value.name == 'Kyoo' ? 
                        (
                            <a href="https://kyoo.id/"><div className={activeProduct !== value.id ? "product cursor-pointer mb-[31px]" : "product cursor-pointer active mb-[31px]"} key={'product-' + value.name} onClick={setSelectedProduct.bind(this, value.id)}>
                        <div className="flex justify-between">
                            <div>
                                {generateProductLogo(value.name)}
                            </div>
                            <div className="w-[60px] h-[25px] bg-[#EEEEFB] rounded-[8px] flex justify-center items-center tag">
                                Gratis
                            </div>
                        </div>
                        <div>
                            {generateProductTagLine(value.name)}
                        </div>
                    </div></a>
                        ) : (
                            <div className={activeProduct !== value.id ? "product cursor-pointer mb-[31px]" : "product cursor-pointer active mb-[31px]"} key={'product-' + value.name} onClick={setSelectedProduct.bind(this, value.id)}>
                        <div className="flex justify-between">
                            <div>
                                {generateProductLogo(value.name)}
                            </div>
                            <div className="w-[60px] h-[25px] bg-[#EEEEFB] rounded-[8px] flex justify-center items-center tag">
                                Gratis
                            </div>
                        </div>
                        <div>
                            {generateProductTagLine(value.name)}
                        </div>
                    </div>
                        )
                    
                
                )
            }
        }
    }

    loopData()

    function generateProductLogo(value) {

        if (value === 'Sikasir') {
            return (
                <img src={Siqasir} alt="sikasir" className="w-[69px]" />
            )
        } else if (value === 'Kyoo') {
            return (
                <img src={Kyoo} alt="kyoo" className="w-[69px]" />
            )
        } else if (value === 'Sikas') {
            return (
                <img src={Sikas} alt="Sikas" className="w-[69px]" />
            )
        }
        else if (value === 'Skrin') {
            return (
                <img src={Skrin} alt="Skrin" className="w-[69px]" />
            )
        }
    }

    function generateProductTagLine(value) {

        if (value === 'Sikasir') {
            return (
                <p className="!text-left">
                    Aplikasi Kasir
                </p>
            )
        } else if (value === 'Kyoo') {
            return (
                <p className="!text-left">
                    Aplikasi Management Antrian
                </p>
            )
        } else if (value === 'Sikas') {
            return (
                <p className="!text-left">
                    Aplikasi Management Keuangan dan Piutang
                </p>
            )
        } else if (value === 'Skrin') {
            return (
                <p className="!text-left">
                    Aplikasi Digital Signage
                </p>
            )
        }
    }

    function logout() {
        ApiService
            .get('/api/logout')
            .then(() => {
                window.location = '/login'
            })
    }

    function addProduct() {
        ApiService
            .put(process.env.REACT_APP_BACKEND_DOMAIN + '/api/company/' + user.companies[0].id, {
                product: {
                    id: activeProduct,
                    status: 'trial'
                }
            })
            .then((response) => {
                
            })
    }

    return (
        <div className="w-full manage-product pb-[25px]">
            <div className='header flex justify-between pl-[20px] pr-[20px] md:pl-[40px] md:pr-[61px] pt-[28px]'>
                <div>
                    <img src={Logo} alt="" />
                </div>
                <div>
                    <button onClick={logout.bind(this)} href="./" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.0112 22.4208L28.5712 19.8608L26.0112 17.3008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M18.3311 19.8608H28.5011" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M20.3311 27.8008C15.9111 27.8008 12.3311 24.8008 12.3311 19.8008C12.3311 14.8008 15.9111 11.8008 20.3311 11.8008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span className="flex-1 whitespace-nowrap text-[#ADADAD]">Keluar</span>
                    </button>
                </div>
            </div>
            <div className='main w-full md:w-[480px] pt-[48px] px-[20px] md:px-0'>
                <div className='title'>
                    <h3>Produk Langganan</h3>
                    <p>Produk yang Anda Subscribe saat ini</p>
                </div>
                <div className='product-list'>
                    {productList}
                </div>
                <div>
                    <button className="btn w-full" disabled={activeProduct === -1} onClick={addProduct.bind(this)}> Tambahkan Produk </button>
                </div>
            </div>
        </div >
    )
}
