import React from 'react';
import AwanDigital from "../../assets/images/logo/awandigital.png"
import TncMenu from "../../components/tncMenu"
import Footer from '../../components/homepage/footer';

import { Link } from 'react-router-dom'


export default function SyaratDasnKetentuan() {
    return (
        <div className="w-full syaratDanKetentuan">
            <div className='smax-w-[1280px] w-full px-[2rem] bg-[#FAFAFA] fixed border-b-[1px] border-[#E2E3E5] h-[85px] flex items-center'>
                <Link to="/">
                    <img src={AwanDigital} alt="" />
                </Link>
            </div>
            <div className='max-w-[1280px] pt-[138px] w-full px-[2rem] flex flex-wrap'>
                <div className='w-[292px] hidden md:flex h-fit'>
                    <TncMenu url={window.location.pathname} />
                </div>
                <div className='contentSyaratDanKetentuan'>
                    <div>
                        <h3>Syarat dan Ketentuan</h3>

                        <h4>Pendahuluan</h4>
                        <p>
                            Syarat & Kebijakan yang ditetapkan di bawah ini mengatur tentang pemakaian platform www.Awan Digital.id dan aplikasi Awan Digital bagi Pengguna yang ditawarkan oleh Awan Digital. Anda diwajibkan memahami dan mematuhi Syarat & Kebijakan ini karena hal ini terkait dengan hak dan kewajiban Anda sebagai Pengguna. Dengan mendaftar dan/atau menggunakan Awan Digital, maka Anda dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam Syarat & Kebijakan. Syarat & Kebijakan ini merupakan bentuk kesepakatan yang dituangkan dalam sebuah perjanjian yang sah antara Anda dengan Awan Digital. Jika Anda tidak menyetujui salah satu, sebagian, atau seluruh isi Syarat & Kebijakan, maka Anda tidak diperkenankan menggunakan layanan Awan Digital. Berikut adalah Syarat dan Kebijakan Pengguna.
                        </p>

                        <h4>
                            Pengguna
                        </h4>
                        <p>
                            Bila anda adalah orang-perorangan/individu maka anda memenuhi kualifikasi untuk dapat menerima lisensi dari Kami dan menggunakan Layanan Awan Digital apabila anda berusia sekurang-kurangnya 18 tahun atau pernah menikah dan memiliki kapasitas hukum untuk mengikatkan diri dalam suatu perjanjian yang sah.
                            <br />
                            <br />
                            Bila anda adalah organisasi atau perusahaan maka anda memenuhi kualifikasi untuk dapat menerima lisensi dari Kami dan menggunakan Layanan Awan Digital apabila: (a) anda adalah badan hukum atau badan usaha yang didirikan secara sah berdasarkan hukum terkait, (b) pihak yang mendaftarkan anda membuat akun ini adalah wakil organisasi atau perusahaan yang sah dan memiliki kapasitas hukum untuk mewakili organisasi atau perusahaan mengikatkan diri dalam suatu perjanjian, (c) anda dan pihak yang mewakili telah mendapat persetujuan yang diperlukan dari pihak atau institusi lain untuk menyetujui S&K ini.
                            <br /><br />
                            Bila Anda tidak memenuhi kualifikasi tersebut maka Anda dilarang menggunakan Layanan Awan Digital.
                        </p>

                        <h4>
                            Akun Pengguna
                        </h4>
                        <p>
                            Untuk dapat mengakses Layanan Awan Digital maka Pengguna wajib membuat sebuah akun melalui Aplikasi atau Website. Pengguna dapat memilih jenis akun yang dibuatnya sesuai penggolongan akun yang ada. Pengguna mengerti bila tiap-tiap jenis akun memiliki beban Biaya Layanan dan hak akses Layanan Awan Digital yang berbeda satu sama lain. Pengguna bertanggungjawab penuh atas kebenaran, keakuratan, kemutakhiran dan kelengkapan data/informasi yang dimasukkannya pada waktu membuat akun. Pengguna bertanggungjawab penuh untuk selalu menjaga dan memastikan bahwa informasi akun tetap benar, akurat, terkini, dan lengkap.
                            <br /><br />
                            Pengguna dapat menyerahkan pengelolaan akun kepada pegawainya yang akan melakukan pengelolaan akun untuk dan atas nama Pengguna. Dalam hal ini Pengguna bertanggungjawab penuh terhadap perbuatan pengelolaan akun yang dilakukan pegawainya tersebut, termasuk namun tidak terbatas kepada setiap kerugian yang diderita Pengguna sendiri, yang diderita oleh Awan Digital atau yang diderita oleh pihak ketiga.
                        </p>

                        <h4>
                            Kebijakan Privasi
                        </h4>
                        <p>
                            Kami berwenang menetapkan Kebijakan Privasi terhadap setiap pihak yang mengakses Aplikasi, Website dan/atau menggunakan Layanan Awan Digital. Setiap Pengguna wajib tunduk kepada Kebijakan Privasi tersebut, yang dapat dilihat pada laman situsweb <span> www.AwanDigital.id/privacy_policy </span>
                        </p>

                        <h4>
                            Perubahan & penghentian Layanan
                        </h4>
                        <p>
                            Pengguna setuju bila Kami dapat menambahkan, melakukan perubahan terhadap, atau menghilangkan seluruh fitur atau fungsi dalam Layanan Awan Digital atau menghentikan Layanan Awan Digital (“Penghentian”). Pengguna mengerti bahwa Kami tidak bisa menjamin bila penambahan, perubahan, penghilangan fitur atau fungsi Layanan Awan Digital yang dilakukan akan mendukung perangkat keras (hardware) yang digunakan oleh Pengguna dan dengan demikian Pengguna membebaskan Awan Digital dari segala kerugian yang diderita Pengguna akibat hardware yang digunakan oleh Pengguna mengalami ketidakcocokan dengan Layanan Awan Digital yang telah ditambah, dirubah, dihilangkan fitur atau fungsinya itu.
                            <br /><br />
                            Pengguna mengerti bahwa Kami tidak bisa menjamin Layanan Awan Digital akan selalu tersedia, baik dalam bentuk saat ini atau bentuk lain apapun juga, atau bahwa Kami akan mendukung, memelihara atau terus menawarkan Layanan Awan Digital, atau versi apapun dari Layanan Awan Digital dan dengan demikian Kami dapat menghentikan penyediaan seluruh atau sebagian Layanan Awan Digital pada setiap waktu. Dalam kondisi ini Pengguna membebaskan Kami dari segala kerugian yang diderita Pengguna akibat penghentian Layanan Awan Digital.
                        </p>

                        <h4>Kebijakan Penggunaan Layanan Yang Dibenarkan</h4>
                        <p>
                            Awan Digital memiliki hak untuk menentukan apakah Pengguna memiliki kualifikasi dan dapat diterima untuk menggunakan Layanan Awan Digital. Dalam hal apapun, Kami dapat melarang, mencegah, atau membatalkan akses terhadap Layanan Awan Digital pada setiap waktu kepada Penggguna dengan alasan yang wajar, yang semata-mata bergantung pada kebijakan Awan Digital tanpa kewajiban bagi Kami untuk mengungkapkan atau menjelaskan kepada pihak manapun.
                            <br /><br />
                            Pengguna bertanggung jawab sepenuhnya atas penggunaan Layanan Awan Digital dalam cara yang wajar dan setuju untuk tidak meminta pertanggungjawaban dari Awan Digital atas segala tuntutan, kerugian, dan/atau kehilangan sehubungan dengan penggunaan Layanan Awan Digital. Tanpa Batasan, Pengguna setuju untuk tidak akan mengaitkan Layanan Awan Digital dengan aktivitas apapun yang mungkin dianggap, termasuk namun tidak terbatas, sebagai:
                            <br /><br />
                            1. Menyalahi atau melanggar hak kekayaan intelektual pihak lain.
                            <br /><br />
                            2. Melanggar hak publikasi atau hak privasi orang lain.
                            <br /><br />
                            3. Ilegal atau mendorong atau sehubungan dengan, baik secara langsung maupun tidak langsung, tindakan ilegal apapun.
                            <br /><br />
                            4. Mengandung virus perangkat lunak atau kode, file, atau program computer lain apapun yang dirancang untuk menginterupsi, menghancurkan, memanipulasi, merusak, atau membatasi fungsi atas suatu perangkat lunak atau perangkat keras komputer apapun atau peralatan telekomunikasi.
                            <br /><br />
                            5. Menjual penggunaan, akses, atau mereproduksi Layanan kepada pihak ketiga manapun. dan/atau
                            <br /><br />
                            6. Suatu penipuan, memperdaya, atau menyesatkan dengan cara apapun.
                            <br /><br />
                            Dengan adanya indikasi atas pelanggaran terhadap kebijakan penggunaan yang dibenarkan, Kami memiliki hak untuk mengakhiri penyediaan Layanan Awan Digital dan melakukan pembatalan akun, tanpa kewajiban apapun untuk mengembalikan, mengganti, atau membayarkan kembali biaya berlangganan dan/atau untuk bertanggung jawab atas segala ganti rugi dalam bentuk apapan, atau untuk menerapkan pembatasan atas penggunaan Layanan Awan Digital oleh Pengguna. Apabila memungkinkan, kami akan berusaha untuk menyediakan pemberitahuan 24 jam sebelumnya dan meminta bahwa akses Layanan Pengguna dikurangi sebelum menerapkan pembatasan apapun. Terlepas dari hal tersebut, Kami memiliki hak untuk membuat laporan apapun kepada institusi pemerintah yang berwenang dan institusi penegak hukum sehubungan dengan adanya indikasi pelanggaran, sebagaimana relevan.
                        </p>

                        <h4>Pembatalan Akun</h4>
                        <p>
                            Awan Digital dapat membatalkan atau menangguhkan akun Pengguna pada setiap waktu jika, berdasarkan diskresi tunggal kami, Pengguna telah melakukan pelanggaran material atau pelanggaran berulang atas S&K ini atau ketentuan lain manapun yang berlaku terhadap penggunaan Layanan Awan Digital oleh Pengguna.
                            <br /><br />
                            Pengguna berhak untuk membatalkan akun Pengguna pada setiap waktu. Tata cara pembatalan akun oleh Pengguna tersedia di Aplikasi atau Website. Pembatalan akun berlaku seketika dan apabila Pengguna membatalkan akun sebelum berakhirnya masa langganan bulanan atau tahunan maka Pengguna tidak memiliki hak atas pengembalian biaya apapun yang telah dibayarkan di muka (kecuali Kami menyetujui sebaliknya).
                            <br /><br />
                            Apabila akun Pengguna dibatalkan maka: (a) akun Pengguna akan dideaktivasi atau dihapus; (b) seluruh hak yang diberikan berdasarkan S&K ini seketika itu berakhir; dan (c) Kami dapat (namun tidak berkewajiban untuk) menghapus informasi dan data Pengguna yang disimpan dalam peladen (server).
                            <br /><br />
                            Pengguna bertanggung jawab untuk menyimpan atau menggandakan sendiri setiap informasi, konten dan data apapun milik Pengguna sebelum pembatalan akun dilaksanakan.
                            <br /><br />
                            Sehubungan dengan pembatalan akun Pengguna atau penangguhan Layanan Awan Digital oleh sebab apapun, beserta seluruh akibat dari pembatalan atau penangguhan itu termasuk namun tidak terbatas kepada terjadinya penghapusan informasi, konten atau data akun Pengguna, terjadinya kerugian atau kerusakan atau akibat lainnya, maka dengan ini Pengguna menyatakan membebaskan Awan Digital dari pertanggung jawaban terhadap Pengguna ataupun pihak ketiga manapun untuk kompensasi, penggantian, atau kerugian dan lain-lain klaim dalam bentuk apapun.
                        </p>

                        <h4>Sifat Layanan</h4>
                        <p>
                            Pengguna mengerti bahwa Layanan Awan Digital diberikan dengan sifat “sebagaimana adanya” dan “sebagaimana tersedia” sehingga segala resiko penggunaan Layanan Awan Digital oleh Pengguna berada pada tanggungan Pengguna sendiri.
                            <br /><br />
                            Pengguna mengerti bila Kami tidak menjamin bahwa: (a) Layanan Awan Digital akan memenuhi kebutuhan khusus Pengguna; (b) Layanan Awan Digital akan tidak terganggu, cepat, aman, atau bebas dari kekeliruan; (c) Layanan Awan Digital akan akurat dan dapat diandalkan; (d) kualitas dari segala produk, layanan, informasi, atau material lain yang dibeli atau diperoleh Pengguna melalui Layanan Awan Digital akan memenuhi ekspektasi Pengguna; atau (e) segala kekeliruan dalam Layanan Awan Digital akan diperbaiki.
                            <br /><br />
                            Kami akan berusaha untuk memberitahukan (pada jam kerja normal) seluruh permasalahan teknis yang muncul sehubungan dengan Layanan Awan Digital.
                        </p>

                        <h4>
                            Pemasok Pihak Ketiga
                        </h4>
                        <p>
                            Pengguna mengakui bahwa Kami dapat menggunakan pemasok pihak ketiga untuk menyediakan perangkat keras, perangkat lunak, jaringan, sambungan, penyimpanan, dan teknologi lain untuk dapat menyediakan Layanan Awan Digital. Tindakan dan kelalaian pemasok pihak ketiga tersebut mungkin di luar kendali Kami maka Pemasok membebaskan Kami dari pertanggung jawaban atas kerugian atau kerusakan yang diderita sebagai akibat dari segala tindakan atau kelalaian oleh pemasok pihak ketiga manapun.
                            <br /><br />
                            Atas nama diri sendiri dan pihak ketiga manapun tersebut, Kami mengecualikan segala jaminan lain yang mungkin tersirat ataupun berlaku berdasarkan peraturan atau undang-undang lain yang berlaku, sepanjang diperkenankan berdasarkan hukum.
                        </p>

                        <h4>
                            Batas Tanggung Jawab Awan Digital
                        </h4>
                        <p>
                            Sepanjang diperkenankan oleh hukum, Kami tidak bertanggung jawab atas segala kerugian langsung, tidak langsung, tidak diduga, khusus, berkelanjutan, atau tipikal, termasuk namun tidak terbatas pada, kerugian atas kehilangan laba, usaha, goodwill, penggunaan, data atau kehilangan tidak berwujud lainnya (sekalipun jika Kami telah mengetahui kemungkinan atas kerugian tersebut), yang diakibatkan oleh: (a) penggunaan atau ketidakmampuan penggunaan Layanan Awan Digital (secara keseluruhan atau sebagian) atau malfungsi teknis apapun: (b) biaya pengadaan barang dan jasa pengganti; (c) akses yang tidak sah pada, atau perubahan dari, komunikasi atau data Pengguna; (d) pernyataan atau tindakan pihak ketiga sehubungan dengan Layanan Awan Digital; (e) hal lain sehubungan dengan Layanan Awan Digital; (f) pelanggaran S&K ini oleh Pengguna; (g) tuntutan atas pelanggaran hak cipta, merek dagang, rahasia dagang, atau hak kekayaan intelektual pihak ketiga lainnya, hak publikasi, hak privasi, atau pencemaran nama baik; (h) pelanggaran oleh Pengguna atas hukum yang berlaku atau perjanjian manapun terkait ketentuan dengan pihak ketiga, dalam hal mana Pengguna terikat; dan/atau (i) hal lainnya sehubungan dengan Layanan dan akses serta penggunaan Layanan Awan Digital oleh Pengguna.
                            <br /><br />
                            Dalam hal apapun, keseluruhan tanggung jawab maksimal dari Kami berdasarkan atau sehubungan dengan S&K ini atau penggunaan Layanan Awan Digital terbatas sebesar Biaya Layanan yang telah Pengguna bayarkan kepada Awan Digital dalam kurun waktu 12 bulan terakhir.
                        </p>

                        <h4>
                            Tanggung Jawab Pengguna
                        </h4>
                        <p>
                            Pengguna bertanggung jawab penuh atas segala aktivitas yang diakibatkan oleh penggunaan Layanan Awan Digital melalui akun Pengguna tanpa ada yang dikecualikan.
                            <br /><br />
                            Pengguna bertanggung jawab untuk memelihara keamanan akun dan kata sandi serta informasi terkait, dan dengan demikian membebaskan Kami dari tanggung jawab atas segala kerugian atau kerusakan yang dapat diakibatkan oleh segala kegagalan Pengguna menjaga keamanan akun dan kata sandi.
                            <br /><br />
                            Pengguna wajib membayar ganti rugi kepada Awan Digital terhadap segala bentuk permintaan tanggung jawab, tindakan, proses hukum, biaya, beban, dan pengeluaran yang dapat dikeluarkan atau diderita Awan Digital sebagai akibat dari penggunaan Layanan Awan Digital melalui akun Pengguna dan/atau sebagai akibat dari kegagalan Pengguna mematuhi S&K ini.
                        </p>

                        <h4>Larangan Penggunaan Layanan Yang Bersifat Kejahatan Atau Ilegal</h4>
                        <p>
                            Dalam menggunakan Layanan Awan Digital ini Pengguna dilarang: (a) menggunakan Layanan Awan Digital dengan cara yang melanggar hukum, untuk tujuan apapun yang melanggar hukum, ilegal atau bersifat penipuan, atau dengan cara apapun yang tidak sesuai dengan S&K ini; (b) melanggar hak kekayaan intelektual Awan Digital atau pihak ketiga manapun; (c) mentransmisikan material apapun yang bersifat merendahkan, menghina, atau tidak pantas; (d) Menggunakan Layanan Awan Digital dengan cara yang dapat merusak, menonaktifkan, membebani, membuat cacat atau menggangu sistem atau keamanan Layanan Awan Digital atau menggangu pengguna lain; (e) memperoleh atau mengumpulkan informasi atau data apapun dari Layanan Awan Digital atau sistem terkait atau mencoba untuk mengurai segala transmisi menuju atau dari server  Layanan Awan Digital yang menjalankan Layanan Awan Digital apapun; (f) mengungkapkan atau mendistribusikan informasi terkait pengguna Layanan Awan Digital lainnya kepada pihak ketiga manapun, atau menggunakan informasi pengguna lain manapun untuk tujuan pemasaran kecuali telah memperoleh persetujuan yang lugas untuk melakukan hal tersebut; atau (g) mengakses atau mendaftarkan login Pengguna melalui bots atau metode terotomasi lainnya.
                        </p>

                        <h4>Hak Kekayaan Intelektual</h4>
                        <p>
                            Pengguna setuju bahwa konten dan segala material lain yang terdapat dalam Layanan, termasuk, namun tanpa batasan hak cipta dan hak kekayaan intelektual lainnya dalam karya seni, grafis, fotografi, teks, video, dan cuplikan suara, merek dagang dan logo yang ada dalam Aplikadi dan/atau Website (secara bersama-sama disebut “Konten”) dimiliki oleh Awan Digital. Pengguna setuju untuk tidak mengajukan keberatan atau melakukan apapun yang tidak sesuai dengan kepemilikan tersebut. Pengguna tidak diperkenankan untuk menyalin, mereproduksi, menggunakan kembali, mentransmisikan kembali, mengadaptasi, mengumukan, memajang, memuat, mengunggah, membagikan, mengubah, menyiarkan, atau membuat karya turunan dari Konten dengan cara apapun, termasuk untuk keperluan publik atau komersial apapun, tanpa persetujuan tertulis sebelumnya dari Awan Digital. Pengguna tidak diberikan layanan sehubungan dengan Konten yang terdapat dalam Aplikasi dan/atau Website. Berdasarkan diskresi tunggal, Awan Digital, pihak terafiliasi Awan Digital, atau pemberi Layanan Awan Digital dapat melakukan upaya-upaya untuk melaksanakan hak kekayaan intelektual mereka sejauh mungkin yang diperkenankan hukum, termasuk untuk melaksanakan proses peradilan kriminal.
                            <br /><br />
                            Pengguna memberikan kepada Awan Digital, anak perusahaan, afiliasi, dan penerus hak Awan Digital suatu hak yang bebas royalti, tidak dapat dibatalkan, dan berlaku terus menerus (termasuk untuk keperluan komersial) untuk menggunakan informasi dan/atau data yang diperoleh Awan Digital melalui penggunaan Layanan Awan Digital oleh Pengguna, dengan ketentuan bahwa Kami akan mengaggregasi atau melakukan anonimisasi informasi atau data tersebut sebelum menggunakannya.
                            <br /><br />
                            Pengguna memberikan kepada Awan Digital , anak perusahaan, afiliasi, dan penerus hak Awan Digital suatu hak yang berlaku di seluruh dunia, non-eksklusif, bebas royalti, telah dibayar penuh, dapat dialihkan, dan dapat disublisensikan untuk menggunakan, mereproduksi, mengubah, mengadaptasi, mengumumkan, membuat karya turunan dari, mendistribusikan, melaksanakan di muka umum, dan mengumumkan di muka umum Konten Pengguna di seluruh dunia dalam media apapun untuk menyediakan dan mempromosikan Layanan Awan Digital, sepanjang diperkenankan peraturan perundang-undangan.
                            <br /><br />
                            Selain dari hak-hak tersebut,Kami tidak mengklaim hak kekayaan intelektual sehubungan dengan informasi dan konten yang Pengguna masukkan ke dalam Layanan Awan Digital.
                            <br /><br />
                            Pengguna dapat memberikan kami komentar, tanggapan, atau saran sehubungan dengan Layanan, dan Pengguna setuju bahwa Kami bebas untuk menggunakan, mengubah, dan menuangkan saran tersebut tanpa suatu kewajiban apapun kepada Pengguna.
                        </p>

                        <h4>Komunikasi</h4>
                        <p>
                            Pengguna mengizinkan Awan Digital untuk melakukan komunikasi atau mengontak Pengguna melalui surat elektronik (surel/email) untuk menyampaikan iklan, material pemasaran, informasi baru, surat kabar, pemberitahuan, dan informasi lain apapun kepada Pengguna melalui email yang Pengguna sampaikan kepada Kami pada saat pembuatan akun dan/atau alamat kontak lain dari waktu ke waktu.
                            <br /><br />
                            Pengguna dapat melakukan komunikasi atau mengontak Awan Digital melalui fasilitas layanan pelanggan ke telepon <span>+62 812-9729-5435</span> atau email ke <span>support@Awandigital.id</span>
                        </p>

                        <h4>Lain-Lain Dan Ganti Rugi</h4>
                        <p>
                            Pengguna setuju untuk mengganti rugi, membela dan memihak Awan Digital dan/atau pihak- pihak terkait lainnya sebagai tidak bersalah dari setiap dan semua keluhan, kerugian, kerusakan, tanggung jawab, biaya dan denda, meliputi tanpa kecuali biaya dan denda hukum,dan hilangnya data yang muncul sebagai akibat dari penggunaan atau penyalahgunaan Layanan ataupun pelanggaran dari Syarat dan Kebijakan ini.
                        </p>

                        <h4>
                            Pembaharuan Syarat dan Kebijakan
                        </h4>
                        <p>
                            Syarat dan Kebijakan ini dapat diolah dan dimodifikasi setiap saat dan dari waktu ke waktu tanpa pemberitahuan sebelumnya. Awan Digital menyarankan Pengguna untuk membaca dan secara seksama dan memeriksa halaman Syarat dan Kebijakan ini setiap waktu. Dengan tetap mengakses dan menggunakan layanan Awan Digital, maka Pengguna dianggap menyetujui setiap perubahan terhadap Syarat & Kebijakan ini.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
