import React, { useRef } from 'react'
import Navbar from '../components/homepage/navbar';
import Section1 from '../components/homepage/section1';
import Section2 from '../components/homepage/section2';
// import Section3 from '../components/homepage/section3';
// import Section4 from '../components/homepage/section4';
import Section5 from '../components/homepage/section5';
import Footer from '../components/homepage/footer';


// Section 3
import '../components/component.scss'
import Wallet from '../assets/images/logo/dompet.png'
import Antri from '../assets/images/logo/antri.png'
import Resto from '../assets/images/logo/restaurant.png'
import Chain from '../assets/images/logo/link.png'
import Store from '../assets/images/logo/store.png'
import Kasir from '../assets/images/logo/kasir.png'

//Section 4
import Banner from '../assets/images/section4/banner.png'
import Thumb from '../assets/images/section4/thumb.png'



export default function Homepage() {
    const mengapaAwanDigital = useRef(null);
    const integrasi = useRef(null);

    // useEffect(() => {
    //     var url = new URL(window.location.href);
    //     if (url.hash != null) {
    //         if (url.has === 'mengapaAwanDigital') {
    //             mengapaAwanDigital.current.scrollIntoView();
    //         } else {
    //             integrasi.current.scrollIntoView();
    //         }
    //     }
    // });


    return (
        <div className="w-screen">
            <Navbar />
            <div className='w-full max-w-[1280px] mx-auto bg-blue'>
                <Section1 />
            </div>
            <Section2 />

            {/* <Section3 /> */}
            <div className='relative section3 w-full' ref={mengapaAwanDigital} id="mengapaAwanDigital">
                <div className="title w-full max-w-[1280px] md:px-[2rem] grid grid-cols-1 md:grid-cols-2"  data-aos="zoom-out">
                    <div className="col-span-1 left">
                        <h4 className="hidden md:block">Mengapa Awan Digital</h4>
                        <h2>
                            Terintegrasi dengan beragam macam solusi aplikasi
                        </h2>
                    </div>
                    <div className="col-span-1 hidden md:block ">
                    </div>
                </div>
                <div className="content w-full max-w-[1280px] md:px-[2rem] grid md:grid-cols-8">
                    <div className="md:col-span-6 grid grid-cols-1 md:grid-cols-3 gap-x-[69px] gap-y-[24px] md:gap-y-[68px]">
                        <div className="col-span-1 flex gap-x-[14px]">
                            <div data-aos="zoom-out">
                                <div className="bg-[#2F5CFD] w-[48px] h-[48px] rounded-[6px]  flex items-center justify-center">
                                    <img src={Antri} alt="" />
                                </div>
                            </div>
                            <div data-aos="zoom-out">
                                <h4>Aplikasi antri dan booking online</h4>
                            </div>
                        </div>
                        <div className="col-span-1 flex gap-x-[14px]">
                            <div data-aos="zoom-out">
                                <div className="bg-[#7373BC] w-[48px] h-[48px] rounded-[6px]  flex items-center justify-center">
                                    <img src={Resto} alt="" />
                                </div>
                            </div>
                            <div data-aos="zoom-out">
                                <h4>Aplikasi pos sistem untuk bisnis makananmu</h4>
                            </div>
                        </div>
                        <div className="col-span-1 flex gap-x-[14px]">
                            <div data-aos="zoom-out">
                                <div className="bg-[#2F5CFD] w-[48px] h-[48px] rounded-[6px]  flex items-center justify-center">
                                    <img src={Chain} alt="" />
                                </div>
                            </div>
                            <div data-aos="zoom-out">
                                <h4>Aplikasi integrasi <br /> koneksi tautanmu</h4>
                            </div>
                        </div>

                        <div className="col-span-1 flex gap-x-[14px]">
                            <div  data-aos="zoom-out">
                                <div className="bg-[#00B469] w-[48px] h-[48px] rounded-[6px]  flex items-center justify-center">
                                    <img src={Kasir} alt="" />
                                </div>
                            </div>
                            <div data-aos="zoom-out">
                                <h4>Aplikasi mencatat dan melihat transaksi di tokomu</h4>
                            </div>
                        </div>
                        <div className="col-span-1 flex gap-x-[14px]">
                            <div data-aos="zoom-out">
                                <div className="bg-[#2F5CFD] w-[48px] h-[48px] rounded-[6px]  flex items-center justify-center">
                                    <img src={Store} alt="" />
                                </div>
                            </div>
                            <div data-aos="zoom-out">
                                <h4>Solusi pembelian barang online</h4>
                            </div>
                        </div>
                        <div className="col-span-1 flex gap-x-[14px]">
                            <div data-aos="zoom-out">
                                <div className="bg-[#00B469] w-[48px] h-[48px] rounded-[6px]  flex items-center justify-center">
                                    <img src={Wallet} alt="" />
                                </div>
                            </div>
                            <div data-aos="zoom-out">
                                <h4>Aplikasi pencatatan transaksi dan utang piutang</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Section4 /> */}
            <div className='relative section4 w-full' ref={integrasi} id="integrasi">
                <div className="content w-full max-w-[1280px] px-[2rem] mx-auto hidden md:grid md:grid-cols-2 pt-[56px]">
                    <div className="col-span-1"  data-aos="zoom-in">
                        <img src={Banner} alt="" />
                    </div>
                    <div className="col-span-1" data-aos="zoom-in">
                        <img src={Thumb}  className="mb-[41px] block" alt="" />
                        <h2 className="mb-[38px]">Memahami segala kebutuhan bisnis anda</h2>
                        <p>Awan Digital berusaha memberi solusi inovatif untuk memajukan kualitas pelayanan Institusi Kecil dan UMKM. Mudahkan Pelanggan dengan experience yang lebih simple dan lebih baik.
                        </p>
                    </div>
                </div>
                <div className="block md:hidden">
                    <div className="">
                        <h2 className="mb-[38px]">Memahami segala kebutuhan bisnis anda</h2>
                        <p>Awan Digital berusaha memberi solusi inovatif untuk memajukan kualitas pelayanan Institusi Kecil dan UMKM. Mudahkan Pelanggan dengan experience yang lebih simple dan lebih baik.
                        </p>
                    </div>
                    <div className="col-span-1">
                        <img src={Banner} alt="" />
                    </div>
                </div>
                <div className="h-[72px] md:h-[137px] -mb-[38px] md:-mb-[68px] top-section w-full max-w-[1280px] px-[2rem] mx-auto absolute bottom-0 left-0 right-0 mx-auto">
                    <svg className="mx-auto w-[72px] h-[72px] md:w-[137px] md:h-[137px]" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M68.5 -6.10352e-05L72.3807 23.5601C75.8517 44.6324 92.3675 61.1483 113.44 64.6192L137 68.4999L113.44 72.3807C92.3675 75.8516 75.8517 92.3675 72.3807 113.44L68.5 137L64.6193 113.44C61.1483 92.3675 44.6325 75.8516 23.5602 72.3807L0 68.4999L23.5602 64.6192C44.6325 61.1483 61.1483 44.6324 64.6193 23.5601L68.5 -6.10352e-05Z" fill="#FAFAFA" />
                    </svg>
                </div>
            </div>

            <Section5 />
            <Footer />
        </div>
    )
}
