import React from 'react'
import Navbar from '../components/homepage/navbar';
import Footer from '../components/homepage/footer';
import LineTop from '../assets/images/usecase/line-top.png'
import TopIcon from '../assets/images-mobile/usecase/top-icon.png'
import Whatsapp from '../assets/images/section5/whatsapp.svg'
import Section5 from '../components/homepage/section5';

export default function Usecase() {
    return (
        <div className="w-full usecase">
            <Navbar />
            <div className='w-full section1'>
                <div className='w-full max-w-[1280px] mx-auto'>
                    <img src={LineTop} className="hidden md:block" alt="" />
                    <img src={TopIcon} className="block md:hidden" alt="" />
                    <div className='content'>
                        <h2>
                            Solusi terbaik untuk perubahan yang lebih baik
                        </h2>
                        <p>
                            Efisiensi proses operasional dengan fokus kepada bisnis inti Anda. Biarkan kami yang menangani solusi untuk Anda
                        </p>
                        <button className="btn">
                            <img src={Whatsapp} alt="" /> WhatsApp Kami
                        </button>
                    </div>
                </div>
            </div>
            <div className='section2-mobile block md:hidden'>
                <h2>Sesuaikan Bisnismu
                    Dengan Usecase Awan Digital</h2>
            </div>
            <div className='w-full section2'>
                <div className='mx-auto w-full max-w-[1280px]'>
                    <h2 className='hidden md:flex'>Sesuaikan Bisnismu <br />
                        Dengan Usecase Awan Digital</h2>
                    <div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-[24px] md:gap-x-[30px] mb-[64px]">
                            <div className="col-span-1">
                                <div>
                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M34.1667 5.125H20.5C19.5604 5.125 18.7917 5.89375 18.7917 6.83333V9.58375C18.7917 9.58375 18.7917 9.58375 18.8088 9.60083L27.3504 17.2883C28.4267 18.245 29.0417 19.6458 29.0417 21.0979V22.2083H32.4583V25.625H29.0417V29.0417H32.4583V32.4583H29.0417V35.875H34.1667C35.1062 35.875 35.875 35.1062 35.875 34.1667V6.83333C35.875 5.89375 35.1062 5.125 34.1667 5.125ZM25.625 11.9583H22.2083V8.54167H25.625V11.9583ZM32.4583 18.7917H29.0417V15.375H32.4583V18.7917ZM32.4583 11.9583H29.0417V8.54167H32.4583V11.9583Z" fill="#4D4EC5" />
                                        <path d="M25.625 34.1667V21.0979C25.625 20.6196 25.42 20.1583 25.0612 19.8337L16.5196 12.1462C16.195 11.8387 15.785 11.7021 15.375 11.7021C14.965 11.7021 14.555 11.8558 14.2304 12.1462L5.68875 19.8337C5.33 20.1412 5.125 20.6025 5.125 21.0979V34.1667C5.125 35.1062 5.89375 35.875 6.83333 35.875H10.25C11.1896 35.875 11.9583 35.1062 11.9583 34.1667V27.3333H18.7917V34.1667C18.7917 35.1062 19.5604 35.875 20.5 35.875H23.9167C24.8562 35.875 25.625 35.1062 25.625 34.1667Z" fill="#4D4EC5" />
                                    </svg>
                                </div>
                                <h3>Smart City</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.
                                </p>
                            </div>
                            <div className="col-span-1">
                                <div>
                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.2082 27.3333H18.7915C17.8519 27.3333 17.0832 26.5646 17.0832 25.625H5.14192V32.4583C5.14192 34.3375 6.67942 35.875 8.55859 35.875H32.4582C34.3373 35.875 35.8748 34.3375 35.8748 32.4583V25.625H23.9165C23.9165 26.5646 23.1478 27.3333 22.2082 27.3333ZM34.1665 11.9583H27.3332C27.3332 8.18292 24.2753 5.125 20.4998 5.125C16.7244 5.125 13.6665 8.18292 13.6665 11.9583H6.83317C4.954 11.9583 3.4165 13.4958 3.4165 15.375V20.5C3.4165 22.3962 4.93692 23.9167 6.83317 23.9167H17.0832V22.2083C17.0832 21.2687 17.8519 20.5 18.7915 20.5H22.2082C23.1478 20.5 23.9165 21.2687 23.9165 22.2083V23.9167H34.1665C36.0457 23.9167 37.5832 22.3792 37.5832 20.5V15.375C37.5832 13.4958 36.0457 11.9583 34.1665 11.9583ZM17.0832 11.9583C17.0832 10.0792 18.6207 8.54167 20.4998 8.54167C22.379 8.54167 23.9165 10.0792 23.9165 11.9583H17.0661H17.0832Z" fill="#FDA92F" />
                                    </svg>
                                </div>
                                <h3>Smart Office</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.
                                </p>
                            </div>
                            <div className="col-span-1">
                                <div>
                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.5 21.7812C23.2846 21.7812 25.7446 22.4475 27.7433 23.3187C29.5883 24.1388 30.75 25.9837 30.75 27.9825V29.0417C30.75 29.9813 29.9813 30.75 29.0417 30.75H11.9583C11.0188 30.75 10.25 29.9813 10.25 29.0417V27.9996C10.25 25.9837 11.4117 24.1388 13.2567 23.3358C15.2554 22.4475 17.7154 21.7812 20.5 21.7812ZM6.83333 22.2083C8.7125 22.2083 10.25 20.6708 10.25 18.7917C10.25 16.9125 8.7125 15.375 6.83333 15.375C4.95417 15.375 3.41667 16.9125 3.41667 18.7917C3.41667 20.6708 4.95417 22.2083 6.83333 22.2083ZM8.76375 24.0875C8.13167 23.985 7.49958 23.9167 6.83333 23.9167C5.14208 23.9167 3.53625 24.2754 2.08417 24.9075C0.82 25.4542 0 26.6842 0 28.0679V29.0417C0 29.9813 0.76875 30.75 1.70833 30.75H7.6875V27.9996C7.6875 26.5817 8.08042 25.2492 8.76375 24.0875ZM34.1667 22.2083C36.0458 22.2083 37.5833 20.6708 37.5833 18.7917C37.5833 16.9125 36.0458 15.375 34.1667 15.375C32.2875 15.375 30.75 16.9125 30.75 18.7917C30.75 20.6708 32.2875 22.2083 34.1667 22.2083ZM41 28.0679C41 26.6842 40.18 25.4542 38.9158 24.9075C37.4638 24.2754 35.8579 23.9167 34.1667 23.9167C33.5004 23.9167 32.8683 23.985 32.2363 24.0875C32.9196 25.2492 33.3125 26.5817 33.3125 27.9996V30.75H39.2917C40.2313 30.75 41 29.9813 41 29.0417V28.0679ZM20.5 10.25C23.3358 10.25 25.625 12.5392 25.625 15.375C25.625 18.2108 23.3358 20.5 20.5 20.5C17.6642 20.5 15.375 18.2108 15.375 15.375C15.375 12.5392 17.6642 10.25 20.5 10.25Z" fill="#FDA92F" />
                                    </svg>
                                </div>
                                <h3>Smart Governance</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.
                                </p>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-[24px] lg:gap-x-[30px] mb-[64px]">
                            <div className="col-span-1">
                                <div>
                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M35.8752 14.5208C33.3981 14.5208 32.0143 16.9808 32.5781 18.8087L26.5135 24.8904C26.001 24.7367 25.2493 24.7367 24.7368 24.8904L20.3806 20.5342C20.9614 18.7062 19.5777 16.2292 17.0835 16.2292C14.6064 16.2292 13.2056 18.6892 13.7864 20.5342L5.99641 28.3071C4.1685 27.7433 1.7085 29.1271 1.7085 31.6042C1.7085 33.4833 3.246 35.0208 5.12516 35.0208C7.60225 35.0208 8.986 32.5608 8.42225 30.7329L16.1952 22.9429C16.7077 23.0967 17.4593 23.0967 17.9718 22.9429L22.3281 27.2992C21.7472 29.1271 23.131 31.6042 25.6252 31.6042C28.1022 31.6042 29.5031 29.1442 28.9222 27.2992L35.0039 21.2346C36.8318 21.7983 39.2918 20.4146 39.2918 17.9375C39.2918 16.0583 37.7543 14.5208 35.8752 14.5208Z" fill="#FDA92F" />
                                        <path d="M25.6252 16.2292L27.231 12.6929L30.7502 11.1042L27.231 9.51541L25.6252 5.97916L24.0535 9.51541L20.5002 11.1042L24.0535 12.6929L25.6252 16.2292Z" fill="#FDA92F" />
                                        <path d="M5.97933 19.6458L6.8335 16.2292L10.2502 15.375L6.8335 14.5208L5.97933 11.1042L5.12516 14.5208L1.7085 15.375L5.12516 16.2292L5.97933 19.6458Z" fill="#FDA92F" />
                                    </svg>
                                </div>
                                <h3>Smart Branch</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.
                                </p>
                            </div>
                            <div className="col-span-1">
                                <div>
                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.2082 27.3333H18.7915C17.8519 27.3333 17.0832 26.5646 17.0832 25.625H5.14192V32.4583C5.14192 34.3375 6.67942 35.875 8.55859 35.875H32.4582C34.3373 35.875 35.8748 34.3375 35.8748 32.4583V25.625H23.9165C23.9165 26.5646 23.1478 27.3333 22.2082 27.3333ZM34.1665 11.9583H27.3332C27.3332 8.18292 24.2753 5.125 20.4998 5.125C16.7244 5.125 13.6665 8.18292 13.6665 11.9583H6.83317C4.954 11.9583 3.4165 13.4958 3.4165 15.375V20.5C3.4165 22.3962 4.93692 23.9167 6.83317 23.9167H17.0832V22.2083C17.0832 21.2687 17.8519 20.5 18.7915 20.5H22.2082C23.1478 20.5 23.9165 21.2687 23.9165 22.2083V23.9167H34.1665C36.0457 23.9167 37.5832 22.3792 37.5832 20.5V15.375C37.5832 13.4958 36.0457 11.9583 34.1665 11.9583ZM17.0832 11.9583C17.0832 10.0792 18.6207 8.54167 20.4998 8.54167C22.379 8.54167 23.9165 10.0792 23.9165 11.9583H17.0661H17.0832Z" fill="#FDA92F" />
                                    </svg>
                                </div>
                                <h3>Health</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.
                                </p>
                            </div>
                            <div className="col-span-1">
                                <div>
                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.5 21.7812C23.2846 21.7812 25.7446 22.4475 27.7433 23.3187C29.5883 24.1388 30.75 25.9837 30.75 27.9825V29.0417C30.75 29.9813 29.9813 30.75 29.0417 30.75H11.9583C11.0188 30.75 10.25 29.9813 10.25 29.0417V27.9996C10.25 25.9837 11.4117 24.1388 13.2567 23.3358C15.2554 22.4475 17.7154 21.7812 20.5 21.7812ZM6.83333 22.2083C8.7125 22.2083 10.25 20.6708 10.25 18.7917C10.25 16.9125 8.7125 15.375 6.83333 15.375C4.95417 15.375 3.41667 16.9125 3.41667 18.7917C3.41667 20.6708 4.95417 22.2083 6.83333 22.2083ZM8.76375 24.0875C8.13167 23.985 7.49958 23.9167 6.83333 23.9167C5.14208 23.9167 3.53625 24.2754 2.08417 24.9075C0.82 25.4542 0 26.6842 0 28.0679V29.0417C0 29.9813 0.76875 30.75 1.70833 30.75H7.6875V27.9996C7.6875 26.5817 8.08042 25.2492 8.76375 24.0875ZM34.1667 22.2083C36.0458 22.2083 37.5833 20.6708 37.5833 18.7917C37.5833 16.9125 36.0458 15.375 34.1667 15.375C32.2875 15.375 30.75 16.9125 30.75 18.7917C30.75 20.6708 32.2875 22.2083 34.1667 22.2083ZM41 28.0679C41 26.6842 40.18 25.4542 38.9158 24.9075C37.4638 24.2754 35.8579 23.9167 34.1667 23.9167C33.5004 23.9167 32.8683 23.985 32.2363 24.0875C32.9196 25.2492 33.3125 26.5817 33.3125 27.9996V30.75H39.2917C40.2313 30.75 41 29.9813 41 29.0417V28.0679ZM20.5 10.25C23.3358 10.25 25.625 12.5392 25.625 15.375C25.625 18.2108 23.3358 20.5 20.5 20.5C17.6642 20.5 15.375 18.2108 15.375 15.375C15.375 12.5392 17.6642 10.25 20.5 10.25Z" fill="#FDA92F" />
                                    </svg>
                                </div>
                                <h3>UMKM</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Section5 />
            <Footer />
        </div >
    )
}
