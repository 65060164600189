import React from 'react';
import AwanDigital from "../../assets/images/logo/awandigital.png"
import TncMenu from "../../components/tncMenu"
import Footer from '../../components/homepage/footer';

import { Link } from 'react-router-dom'


export default function KebijakanPrivasi() {
    return (
        <div className="w-full syaratDanKetentuan">
            <div className='max-w-[1280px] w-full px-[2rem] bg-[#FAFAFA] fixed border-b-[1px] border-[#E2E3E5] h-[85px] flex items-center'>
                <Link to="/">
                    <img src={AwanDigital} alt="" />
                </Link>
            </div>
            <div className='max-w-[1280px] pt-[138px] w-full px-[2rem] flex flex-wrap'>
                <div className='w-[292px] hidden md:block h-fit'>
                    <TncMenu url={window.location.pathname} />
                </div>
                <div className='contentKebijakanPrivasi'>
                    <div>
                        <h3>
                            Kebijakan Privasi
                        </h3>

                        <h4>
                            Pengumpulan Data Diri
                        </h4>
                        <p>
                            Pada saat Anda mendaftar untuk membuat Akun Pengguna, menjalani proses verifikasi identitas, melakukan transaksi dan aktivitas lain melalui Aplikasi atau Website yang membutuhkan data pribadi maka Anda akan diminta untuk memberikan data pribadi termasuk namun tidak terbatas kepada data nama, alamat email, alamat usaha, nomor telpon, nomor telpon seluler (ponsel), tanggal lahir dan data pribadi lainnya yang Anda unggah ke Website serta yang ada di dalam perangkat yang anda gunakan untuk menginstal Aplikasi. Kami juga mengumpulkan data pribadi dengan cookies dan teknologi lainnya yang kami gunakan untuk membangun Aplikasi dan Website.
                        </p>

                        <h4>
                            Penggunaan Data Pribadi
                        </h4>
                        <p>
                            Data pribadi tersebut kami kumpulkan untuk keperluan administrasi, penagihan biaya Layanan dan biaya lainnya, menjalankan Layanan Awan Digital sebagaimana mestinya, juga untuk keperluan pemasaran dan keperluan lain sehubungan pengembangan Layanan Awan Digital.
                            <br /><br />
                            Awan Digital menjamin tidak akan menjual, menyewakan atau mengungkapkan data pribadi Anda kepada pihak lain (kecuali apabila pengungkapan itu Kami lakukan sehubungan dengan suatu proses hukum, bila diwajibkan oleh hukum yang berlaku, untuk keperluan perpajakan atau dalam hal terjadi transfer aset).
                        </p>

                        <h4>
                            Berbagi Data Pribadi
                        </h4>
                        <p>
                            Anda dengan ini setuju bahwa Informasi Pribadi Anda dapat dibagikan kepada afiliasi atau vendor kami untuk tujuan pemrosesan yang mungkin diperlukan dalam pemeliharaan dan pelaksanaan tugas dan tanggung jawab layanan kami, sesuai dengan hukum, aturan, dan peraturan yang berlaku. Kami mungkin, jika perlu, melibatkan subkontraktor atau pemroses data dalam setiap kasus sehubungan dengan tujuan yang disebutkan di atas. Semua perjanjian pemrosesan data, subkontrak atau pembagian data akan sesuai dengan persyaratan berdasarkan Peraturan Menteri Komunikasi Dan Informatika Nomor 20 Tahun 2016 tentang Perlindungan Data Pribadi Dalam Sistem Elektronik, dan/atau undang-undang dan peraturan yang berlaku yang mungkin akan disahkan dan diimplementasikan kemudian. Sejauh pemrosesan, subkontrak, atau pembagian semacam itu membutuhkan pemberitahuan atau persetujuan, kami akan memberikan atau meminta pemberitahuan dan / atau persetujuan tersebut, dalam setiap kasus, sebelum pemrosesan atau pembagian Informasi Pribadi Anda (jika berlaku). Kecuali sebagaimana yang ditetapkan di sini, kami tidak akan memberikan Informasi Pribadi Anda kepada pihak ketiga tanpa persetujuan khusus Anda
                        </p>

                        <h4>
                            Keamanan Data Pribadi Anda
                        </h4>
                        <p>
                            Kami menghargai Informasi Pribadi yang dikumpulkan melalui Situs kami, dan akan mengambil langkah-langkah yang wajar termasuk perlindungan administratif, fisik dan teknis, untuk melindunginya dari kemungkinan ancaman, baik online maupun offline. Namun, karena sifat Internet yang terbuka, kami tidak menjamin akses yang tidak sah oleh pihak ketiga dan Anda menyadari situasi semacam ini. Jika informasi tersebut dikompromikan sebagai akibat dari pelanggaran keamanan, kami akan mengambil langkah yang wajar untuk menyelidiki situasi dan jika perlu, memberi tahu individu yang informasinya mungkin telah disusupi dan mengambil langkah lain sesuai dengan hukum atau peraturan yang berlaku . Sejauh diizinkan oleh undang-undang, kami tidak bertanggung jawab dan berkewajiban atas segala kerugian, kerugian, dan / atau klaim langsung, tidak langsung, konsekuensial, atau hukuman, yang mungkin Anda derita karena kehilangan, akses tidak sah, penyalahgunaan, atau pengubahan dari informasi apa pun yang Anda kirimkan ke Situs.
                        </p>

                        <h4>
                            Kerahasiaan Akun
                        </h4>
                        <p>
                            Anda harus bertanggung jawab untuk menjaga kerahasiaan akun dan kata sandi Anda sendiri, serta setiap dan semua aplikasi yang diajukan, kewajiban yang disetujui atau dimasukkan ke dalam dan semua kegiatan lain yang terjadi di bawah akun tersebut. Anda setuju untuk segera memberi tahu kami tentang penggunaan tidak sah atau pengungkapan akun atau kata sandi Anda, setiap kegiatan tidak sah di bawah akun Anda atau pelanggaran keamanan lainnya. Kami tidak akan bertanggung jawab atas kerugian apa pun yang mungkin Anda alami akibat penggunaan akun atau kata sandi oleh pihak ketiga, baik dengan atau tanpa sepengetahuan Anda, tanpa kesalahan atau kelalaian dari pihak kami
                        </p>

                        <h4>
                            Transfer Aset
                        </h4>
                        <p>
                            Selama bisnis kami, kami dapat menjual atau membeli aset. Jika entitas lain mengakuisisi kami atau semua atau secara substansial semua aset kami, Informasi yang kami kumpulkan tentang Anda dapat ditransfer ke entitas tersebut. Juga, jika ada pailit atau reorganisasi yang dilakukan oleh atau terhadap kami, informasi tersebut dapat dianggap sebagai aset milik kami dan dapat dijual atau dialihkan kepada pihak ketiga. Jika hal-hal di atas terjadi, Anda setuju untuk memberikan kami izin Anda dan hak untuk kami mentransfernya ke pihak ketiga tanpa pemberitahuan lebih lanjut, sejauh diizinkan oleh undang-undang dan peraturan yang berlaku.
                        </p>

                        <h4>
                            Peraturah Pemerintah
                        </h4>
                        <p>
                            Setiap referensi terhadap hukum dan peraturan yang berlaku berdasarkan Kebijakan Privasi ini harus ditafsirkan sesuai dengan ketentuan yang tercantum di bawah hukum dan peraturan Republik Indonesia.
                        </p>

                        <h4>
                            Keterpisahan
                        </h4>
                        <p>
                            Jika ada ketentuan atau ketentuan dari Kebijakan Privasi ini akan diadakan atau dinyatakan tidak sah, ilegal atau tidak dapat diberlakukan untuk alasan apa pun oleh aturan hukum atau kebijakan publik, ketentuan tersebut harus diputus sejauh tidak valid atau tidak dapat dilaksanakan dan ketentuan lainnya akan terus berlanjut dengan kekuatan penuh dan efek.
                        </p>

                        <h4>
                            Hubungi Kami
                        </h4>
                        <p>
                            Untuk setiap pertanyaan, kekhawatiran atau klarifikasi tentang Kebijakan Privasi kami, kami dapat dihubungi melalui email ke <span>support@awandigital.id</span>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
