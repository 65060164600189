import React from "react";
import Logo from '../../assets/images/logo/awandigital.png'
import { Link } from 'react-router-dom'
import Sikas from '../../assets/images-mobile/navbar/sikas.png'
import Kyoo from '../../assets/images-mobile/navbar/kyoo.png'
import Siqasir from '../../assets/images-mobile/navbar/siqasir.png'
import '../component.scss'
export default function Navbar() {

    function menu() {
        var navIcon = document.getElementById('nav-icon2')
        var navbar = document.getElementsByClassName('navbar-mobile')[0]
        navIcon.classList.toggle('open');
        navbar.classList.toggle('hidden');
    }

    function openSubMenu(){
        var arrow = document.getElementById('arrow')
        arrow.classList.toggle('rotate-180')
        var submenu = document.getElementsByClassName('submenu')[0]
        submenu.classList.toggle('hidden')
    }

    window.onscroll = () => {
        let top = window.scrollY;
        var navbarFull = document.getElementById('navbar-full')
        if (top > 0) {
            navbarFull.style.backgroundColor = '#FFFFFF'
        } else {
            navbarFull.style.borderColor = ''
            navbarFull.style.backgroundColor = 'transparent'
        }
    }

    return (
        <div className="fixed border-b-[1px] border-[#E2E3E5] w-full max-w-[1280px] lg:max-w-full top-0 z-10 h-[51px] md:h-[85px]" id="navbar-full">
            <div className="max-w-[1280px] w-full navbar md:py-[16.53px] absolute top-0 mx-auto left-0 right-0 z-[10] md:pl-[2rem]">
                <div className="flex-none -mt-[11px] md:mt-[0] md:py-[0.8px] px-[19.83px] md:p-0 lg:p-0 ">
                    <a className="" href="./">
                        <img src={Logo} alt="awan-digital" className="w-[90px] md:w-[126.32px] h-[31.87px] md:h-[48px]" />
                    </a>
                </div>
                <div className="hidden md:block lg:block flex-1 pl-[15px] h-fit">
                    <ul className="menu menu-horizontal p-0">
                        <li className="title"><a href="/#mengapaAwanDigital"> Mengapa Awan Digital</a>
                        </li>
                        <li className="title-sub" tabIndex="0">
                            <a href="./">
                                Produk
                                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                            </a>
                            <ul className="p-2 bg-base-100 mega-menu flex-wrap">
                                <li className="w-6/12">
                                    <a href="https://www.kyoo.id/" className="flex flex-wrap">
                                        <div className="kyoo"></div>
                                        <div className="w-fit">
                                            <h3>Kyoo</h3>
                                            <h4>Solusi Antri dan Booking Online</h4>
                                        </div>
                                    </a>
                                </li>
                                {/* <li className="w-6/12">
                                    <a href="./" className="flex flex-wrap">
                                        <div className="web-toko"></div>
                                        <div className="w-fit">
                                            <h3>Web Toko</h3>
                                            <h4>Solusi pembelian barang online</h4>
                                        </div>
                                    </a>
                                </li> */}
                                <li className="w-6/12">
                                    <a href="https://sikas.netlify.app/" className="flex flex-wrap">
                                        <div className="sikas"></div>
                                        <div className="w-fit">
                                            <h3>SiKas</h3>
                                            <h4>Solusi Pencatatan Keuangan Usaha atau Pribadi</h4>
                                        </div>
                                    </a>
                                </li>
                                {/* <li className="w-6/12">
                                    <a href="./" className="flex flex-wrap">
                                        <div className="siresto"></div>
                                        <div className="w-fit">
                                            <h3>Si Resto</h3>
                                            <h4>Solusi pos sistem untuk bisnis makananmu</h4>
                                        </div>
                                    </a>
                                </li> */}
                                <li className="w-6/12">
                                    <a href="https://www.siqasir.com" className="flex flex-wrap">
                                        <div className="siqasir"></div>
                                        <div className="w-fit">
                                            <h3>SiQasir</h3>
                                            <h4>Solusi Pencatatan Transaksi Usahamu</h4>
                                        </div>
                                    </a>
                                </li>
                                <li className="w-6/12">
                                    <a href="./" className="flex flex-wrap">
                                        <div className="tautan"></div>
                                        <div className="w-fit">
                                            <h3>Skrin</h3>
                                            <h4 className="break-word">Solusi Digital Signage dan Meeting Room Booking</h4>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="title"><Link to="/usecase">Usecase</Link>
                        </li>
                        <li className="title"><a href="./#integrasi">Integrasi</a></li>
                    </ul>
                </div>
                <div className="hidden md:block lg:block absolute right-[2rem]">
                    <ul className="menu menu-horizontal p-0">
                        <li>
                            <Link to="/login" className="btn btn-login capitalize"> Masuk </Link>
                        </li>
                        <li className="ml-[13px]">
                            <Link to="/register" className="btn btn-register capitalize"> Daftar </Link>
                        </li>
                    </ul>
                </div>
                <div className="flex-none block md:hidden lg:hidden">
                    <div className="" id="nav-icon2" onClick={menu.bind(this)} data-menu="3">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div className="navbar-mobile hidden w-full bg-white absolute z-[8]">
                <div className="w-full px-[20px]">
                    <div className="relative">
                        <ul>
                            <li>
                                Solusi
                            </li>

                            <li className="flex flex-wrap" id="product" onClick={openSubMenu.bind(this)}>
                                <div className="basis-6/12">
                                    Produk
                                </div>
                                <div className="basis-6/12 flex justify-end">
                                    <svg id="arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.7998 1.39961L4.9998 4.59961L8.1998 1.39961" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </li>

                            <li>
                                Integrasi
                            </li>

                            <li>
                                Mengapa Awan Digital
                            </li>
                        </ul>

                        <div className="absolute top-[40px] submenu hidden">
                            <div className="flex flex-wrap gap-x-[14px] mb-[10px]">
                                <div className="img-product">
                                    <img src={Kyoo} alt="" />
                                </div>
                                <div className="title-product">
                                    <div className="pt-[10px]">
                                        <h4>Kyoo</h4>
                                        <p>Solusi antri dan booking online</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap gap-x-[14px] mb-[10px]">
                                <div className="img-product">
                                    <img src={Sikas} alt="" />
                                </div>
                                <div className="title-product">
                                    <div>
                                        <h4>SiKas</h4>
                                        <p>Solusi pencatatan transaksi dan utang piutang</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap gap-x-[14px] mb-[10px]">
                                <div className="img-product">
                                    <img src={Siqasir} alt="" />
                                </div>
                                <div className="title-product">
                                    <div>
                                        <h4>SiQasir</h4>
                                        <p>Solusi mencatat dan melihat transaksi di tokomu</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap gap-x-[14px] mb-[10px]">
                                <div className="img-product">
                                    <img src={Siqasir} alt="" />
                                </div>
                                <div className="title-product">
                                    <div>
                                        <h4>SiQasir</h4>
                                        <p>Solusi mencatat dan melihat transaksi di tokomu</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="action">
                        <Link to="/login" className="btn login">
                            Masuk
                        </Link>
                        <Link to="/register" className="btn register">
                            Daftar
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}