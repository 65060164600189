import './App.css';
import { Outlet } from 'react-router-dom'

function App() {
  return (
    <div data-theme="pastel" className=" min-h-[100vh] h-fit w-full bg-[#FCFCFC] h-screen">
      <div className='App'>
        <Outlet />
      </div>
    </div>
  );
}

export default App;
