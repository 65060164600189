import React, { useState, useEffect } from 'react'
import Logo from '../assets/images/logo/awandigital.png';
import Kyoo from '../assets/images/logo/kyoo.png';
import Sikas from '../assets/images/logo/sikas.png';
import Siqasir from '../assets/images/logo/siqasir.png';
import Skrin from '../assets/images/logo/skrin.png';
import ApiService from '../../src/services/api.service';
import { Link, useNavigate } from 'react-router-dom'

export default function Dashboard() {
    const navigate = useNavigate()
    const [products, setProducts] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [user, setUser] = useState({});
    useEffect(() => {
        if (isLoaded === false) {
            setIsLoaded(true)
            ApiService.init()
            ApiService
                .get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/get-my-data')
                .then((response) => {
                    let data = response.data.data
                    ApiService
                        .get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/company/' + data.companies[0].id)
                        .then((product) => {
                            setProducts(product.data.data.products);
                            setUser({ ...data })
                        })
                })
                .catch((error) => {
                    navigate('/login')
                })
        }
    }, [isLoaded])

    function productLogo(value) {
        if (value === 'Kyoo') {
            return (
                <img src={Kyoo} className="w-[68px]" alt="" />
            )
        } else if (value === 'Sikas') {
            return (
                <img src={Sikas} className="w-[74.83px]" alt="" />
            )
        } else if (value === 'Sikasir') {
            return (
                <img src={Siqasir} className="w-[74.83px]" alt="" />
            )
        } else if (value === 'Skrin') {
            return (
                <img src={Skrin} className="w-[74.83px]" alt="" />
            )
        }
    }

    var productList = [];
    function loopData() {
        if (products.length !== 0) {
            for (const [index, value] of products.entries()) {
                productList.push(
                    <li key={index}>
                        <div>
                            <div className='product-header cursor-pointer' onClick={goToApp.bind(this, 'signage')}>
                                <div className='flex justify-between items-center'>
                                    <div>
                                        {productLogo(value.name)}
                                    </div>
                                    <div>
                                        <div className='license-type lite'>
                                            {value.pivot.status}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p>Expired at {value.pivot.expiredAt}</p>
                                </div>
                            </div>
                            <div className='go-to-product'>
                                <Link to={'/manage-subscription/' + value.id} className='flex justify-between items-center'>
                                    <div>
                                        <p>Manage Subscriptions</p>
                                    </div>
                                    <div>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.5999 17.792L14.3999 12.992L9.5999 8.19199" stroke="black" strokeWidth="1.23533" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </li>
                )
            }
        }
    }

    loopData()

    function goToApp(app) {
        if(app == 'signage') {
            window.location = process.env.REACT_APP_SIGNAGE_DOMAIN
        }
    }

    function logout() {
        ApiService
            .get('/api/logout')
            .then(() => {
                window.location = '/login'
            })
    }

    function manageProduct() {
        window.location = '/manage-product'
    }

    function RoleButton() {
        if (Object.keys(user).length !== 0) {
            if (user.companies[0].pivot.status != 'Staff') {
                return (
                    <div>
                        <button className="btn w-full" onClick={manageProduct.bind(this)}> Manage Product </button>
                    </div>
                )
            }
        }

    }

    return (
        <div className="w-full dashboard pb-[25px]">
            <div className='header flex justify-between pl-[20px] pr-[20px] md:pl-[40px] md:pr-[61px] pt-[28px]'>
                <div>
                    <img src={Logo} alt="" />
                </div>
                <div>
                    <button onClick={logout.bind(this)} href="./" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.0112 22.4208L28.5712 19.8608L26.0112 17.3008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M18.3311 19.8608H28.5011" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M20.3311 27.8008C15.9111 27.8008 12.3311 24.8008 12.3311 19.8008C12.3311 14.8008 15.9111 11.8008 20.3311 11.8008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span className="flex-1 whitespace-nowrap text-[#ADADAD]">Keluar</span>
                    </button>
                </div>
            </div>
            <div className='main w-full md:w-[480px] pt-[48px] px-[20px] md:px-0'>
                <div className='title'>
                    <h3>Produk Langganan</h3>
                    <p>Produk yang Anda Subscribe saat ini</p>
                </div>
                <div className='product-list'>
                    <ul>
                        {productList}
                    </ul>
                </div>
                <RoleButton />

            </div>
        </div >
    )
}
