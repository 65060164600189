import React from "react";
import Background from '../../assets/images/section1/section-1.png'
import BackgroundMobile from '../../assets/images-mobile/section1/background.png'
import '../component.scss'
export default function Section1() {
    return (
        <div className="w-full section1">
            <div className='pt-[52px] md:pt-[130px] w-full max-w-[1280px] section1 md:grid md:grid-cols-2 md:px-[2rem]'>
                <div className="col-span-1 px-[20px] md:px-[0]" data-aos="zoom-out">
                    <svg className="hidden md:block" width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.12161 11.8431C7.77047 11.3341 7.05868 11.2211 6.61722 11.6541C3.31235 14.8956 2.71593 20.1389 5.43442 24.0796C8.15292 28.0202 13.2663 29.3245 17.4701 27.3861C18.0317 27.1272 18.1788 26.4216 17.8276 25.9126L17.1975 24.9992C16.879 24.5375 16.262 24.4073 15.7387 24.609C13.0142 25.6587 9.82672 24.7528 8.09573 22.2436C6.36475 19.7344 6.64978 16.433 8.59854 14.2588C8.97286 13.8412 9.07026 13.2182 8.75179 12.7566L8.12161 11.8431Z" fill="#7373BC" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.7988 9.13481C25.3987 8.98461 25.7543 8.35773 25.5037 7.7924C23.628 3.56024 18.9273 1.16185 14.2834 2.32471C9.63937 3.48757 6.62366 7.81813 6.96371 12.4348C7.00914 13.0515 7.6182 13.4368 8.21805 13.2866L9.29457 13.0171C9.83862 12.8809 10.177 12.3488 10.1718 11.788C10.1449 8.86839 12.1117 6.20146 15.0687 5.46102C18.0257 4.72058 21.0172 6.14596 22.3693 8.73372C22.6291 9.2308 23.1782 9.5406 23.7223 9.40437L24.7988 9.13481Z" fill="#7373BC" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M29.7797 23.295C30.0452 23.8534 30.7299 24.0784 31.2347 23.7213C35.0139 21.0479 36.4383 15.9666 34.3825 11.6431C32.3267 7.31958 27.4865 5.21705 23.0275 6.4608C22.4319 6.62694 22.1742 7.30002 22.4398 7.85847L22.9163 8.86071C23.1572 9.3672 23.7455 9.59402 24.2943 9.47836C27.1512 8.87624 30.1536 10.2785 31.4626 13.0315C32.7716 15.7844 31.9641 18.9982 29.6938 20.8341C29.2577 21.1867 29.0623 21.7862 29.3031 22.2927L29.7797 23.295Z" fill="#FDA92F" />
                    </svg>
                    <h2
                        className="font-bold text-[50px] lg:text-[74px] leading-[64px] lg:leading-[88px] text-[#2E2E2E] tagline">
                        <div className="ml-[0%]">
                            Awan digital
                        </div>
                        <div className="flex">
                            <ol className="words">
                                <li>Hubungkan</li>
                                <li>Tingkatkan</li>
                                <li>Kembangkan</li>
                                <li>Support</li>
                            </ol>
                            <svg className="hidden md:block" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.1499 7.31695C12.2566 6.70786 11.8286 6.12804 11.2102 6.12701C6.58101 6.11932 2.47814 9.43817 1.65172 14.1537C0.8253 18.8692 3.555 23.3855 7.91092 24.9525C8.49278 25.1618 9.0925 24.7621 9.19925 24.153L9.39082 23.0599C9.48764 22.5075 9.1392 21.982 8.62476 21.7586C5.94659 20.5958 4.31011 17.7144 4.83633 14.7118C5.36255 11.7092 7.88138 9.55605 10.7954 9.37328C11.3551 9.33818 11.8615 8.96247 11.9583 8.41005L12.1499 7.31695Z" fill="#7373BC" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M25.9369 17.0845C26.4698 17.3982 27.163 17.2008 27.3809 16.6221C29.0125 12.29 27.3444 7.2834 23.2187 4.85485C19.0931 2.42631 13.9061 3.39766 10.9102 6.92673C10.51 7.39815 10.6739 8.09999 11.2068 8.41368L12.1631 8.97664C12.6465 9.26114 13.2608 9.11927 13.6505 8.71592C15.6792 6.61609 18.9516 6.09475 21.5786 7.64111C24.2056 9.18747 25.338 12.3017 24.4866 15.0945C24.3231 15.631 24.4972 16.237 24.9805 16.5215L25.9369 17.0845Z" fill="#7373BC" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.5552 30.6705C19.3527 31.2548 19.6829 31.8954 20.2932 31.995C24.862 32.7403 29.4413 30.1177 31.0087 25.5941C32.576 21.0706 30.6009 16.177 26.5503 13.9359C26.0092 13.6365 25.3535 13.9355 25.1511 14.5198L24.7877 15.5684C24.6041 16.0983 24.8644 16.6727 25.3366 16.9751C27.7953 18.5499 28.9517 21.6553 27.9537 24.5356C26.9557 27.416 24.1259 29.1402 21.2201 28.8563C20.6619 28.8018 20.1021 29.092 19.9185 29.6219L19.5552 30.6705Z" fill="#FDA92F" />
                            </svg>
                        </div>
                        <div className="ml-[0%] hidden md:block">
                            digitalisasi yang
                        </div>
                        <div className="ml-[0%] hidden md:block">
                            baik.
                        </div>
                        <div className="ml-[0%] block md:hidden">
                            digitalisasi
                        </div>
                        <div className="ml-[0%] block md:hidden">
                            yang baik.
                        </div>
                    </h2>
                    <p className="hidden md:block">
                        Membantu membuat usaha bisnismu lebih efisiensi dan cepat
                    </p>

                    <div>
                        <div>
                            <button className="btn lihat-produk capitalize">Lihat Produk</button>
                        </div>
                    </div>
                </div>
                <div className="col-span-1">
                    <img className="hidden md:block" src={Background} data-aos="zoom-out"data-aos-once="false" alt="" />
                    <img className="block md:hidden" src={BackgroundMobile} alt="" />
                </div>
            </div>
        </div>
    );
}