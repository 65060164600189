import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import Homepage from './pages/Homepage'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import SyaratDanKetentuan from './pages/auth/SyaratDanKetentuan'
import ForgotPassword from './pages/auth/ForgotPassword'
import KebijakanPrivasi from './pages/auth/KebijakanPrivasi';
import Usecase from './pages/Usecase';
import Dashboard from './pages/Dashboard';
import ManageSubscription from './pages/ManageSubscription';

import AOS from 'aos';
import 'aos/dist/aos.css';
import reportWebVitals from './reportWebVitals';
import ApiService from './services/api.service';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ResetPassword from './pages/auth/ResetPassword';
import ManageProduct from './pages/ManageProduct';

AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 600, // values from 0 to 3000, with step 50ms
  easing: 'ease-in-out', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: true, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={<App />}>
        <Route
          path="/usecase"
          element={<Usecase />} />
        <Route
          path="/"
          element={<Homepage />} />
        <Route
          path="/login"
          element={<Login />} />
        <Route
          path="/register"
          element={<Register />} />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />} />
        <Route
          path="/password-reset/:token"
          element={<ResetPassword />} />
        <Route
          path="/syarat-dan-ketentuan"
          element={<SyaratDanKetentuan />} />
        <Route
          path="/kebijakan-privasi"
          element={<KebijakanPrivasi />} />
        <Route
          path="/dashboard"
          element={
            <Dashboard />
          } />
        <Route
          path="/manage-product"
          element={
            <ManageProduct />
          } />
        <Route
          path="/manage-subscription/:id"
          element={<ManageSubscription />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

ApiService.init()
reportWebVitals();